import React from "react"
import { Helmet } from "react-helmet"

export default ({ subtitle = "" }) => (
  <Helmet>
    <html lang="en" />
    <meta charSet="utf-8" />
    <meta http-equiv="x-ua-compatible" content="ie=edge" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <title>The Weight of Love{subtitle !== "" ? ` - ${subtitle}` : ""}</title>
    <link rel="canonical" href="https://the-weight-of-love.com" />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <meta name="msapplication-TileColor" content="#252041" />
    <meta name="theme-color" content="#EB332A" />
    <meta name="subject" content="The Weight of Love" />
    <meta name="description" content="The novel written by Sunna Bohlen" />
    {/*
      <meta property="og:url" content="the-weight-of-love.com" />
      <meta property="og:title" content="The Weight of Love" />
      <meta property="og:type" content="book" />
      <meta property="og:description" content="Born in South Korea on the day the Korean War begins, Hana struggles to overcome the restrictions of her family, her physical disability, and traditional society. Against the odds, she‘s determined to pursue her creativity, to live and love freely. " />
      <meta property="og:img" content="画像URL" />
      <meta property="og:site_name" content="The Weight of Love" />
      <meta property="og:local" content="en_us" />

      <!-- twitter card -->
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@_weightoflove_" />
    */}
  </Helmet>
)
